<template>
  <div>
    <b-row>
      <b-col md="7">
        <b-card title="Twój bezpośredni kontakt z egzaminatorem OKE.">
          <img
              :src="agnieszka"
              alt="ChemMystery"
              class="agnieszka"
              style="height: 250px; float: left; margin-right: 25px;"
          >
          <b-card-text>
            Tutaj możesz kontaktować się z egzaminatorem OKE (a zarazem doktorem nauk chemicznych). <br />
            Możesz także przesyłać zdjęcia z Twoimi rozwiązaniami zadań - dr Agnieszka Kaczyńska sprawdzi Twoje rozwiązanie/odpowiedź.<br />
            Odpowiedź pojawia się do 72h.<br /><br />
            <strong>Masz 3 pytania w każdym miesiącu.</strong>
            <br /><br />
            O odpowiedzi na pytanie przez egzaminatora OKE zostaniesz powiadomiony mailowo.
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="5">
        <b-card class="card-congratulation-medal">
          <h5>Przejdź do ChemMystery!</h5>
          <b-card-text class="font-small-3">
            Tutaj znajdziesz zawartość ChemMystery
          </b-card-text>
          <br />
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :to="`/moje-kursy/${this.data.courseChemMystery.slug}`"
          >
            Kliknij tutaj
          </b-button>
          <b-img
              :src="require('@/assets/images/chemmaster/chemmystery.png')"
              class="congratulation-medal"
              alt="ChemMystery"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Zadanie pytania do egzaminatora:">
      <div class="row mb-3">
        <div class="col-sm-8">
          <div class="border-1 border-light p-1">
            <p class="mb-0">Pozostałe pytania w tym miesiącu to <strong>{{ data.leftQuestions }}/3</strong></p>
          </div>
        </div>
        <div class="col-sm-4" v-if="data.leftQuestions > 0">
          <button v-b-modal="'modal-chemmystery'" type="button" class="btn btn-primary btn-lg">
            Zadaj pytanie egzaminatorowi OKE
          </button>
        </div>
      </div>
      <div>
        <b-modal ref="modal-chemmystery"  id="modal-chemmystery" hide-footer title="Dodaj pytanie">
          <label for="new-question">Zadaj pytanie egzaminatorowi OKE</label>
          <b-form-textarea
              id="new-question"
              placeholder="Pytanie do egzaminatora"
              rows="5"
              v-model="new_question"
          />

          <b-form-file
              class="mt-1"
              v-model="file"
              placeholder="Wybierz zdjęcie i dodaj..."
              drop-placeholder="Dodaj zdjęcie tutaj..."
              ref="fileInput"
          />

          <b-button class="mt-2" variant="outline-success" block @click="addQuestion">Dodaj pytanie</b-button>
        </b-modal>
      </div>

      <div class="border-1 border-light border-radius-2 p-1" v-for="question in data.questions">
        <b-row>
          <b-col md="8">
            <div>
              <h5>Pytanie:</h5>
              <p>{{ question.question }}</p>
              <div v-if="question.image !== ''">
                Dodane zdjęcie / plik: <a :href="question.image" target="_blank">kliknij tutaj</a>
              </div>
            </div>
            <div v-if="question.status !== 'Oczekuje na odpowiedź'">
              <h5>Odpowiedź egzaminatora OKE:</h5>
              <div v-html="question.answer"></div>
            </div>
          </b-col>
          <b-col md="4">
            <h5>Status:</h5>
            <b-badge variant="primary">{{ question.status }}</b-badge>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div style="position: fixed; bottom: 15px; right: 15px;">
      <audio id="chemmystery-audio" :src="require('@/assets/music/chemmystery.mp3')" autoplay loop></audio>
      <b-button-group>
        <b-button variant="success" onclick="document.getElementById('chemmystery-audio').play()">
          <feather-icon icon="PlayIcon"/>
        </b-button>
        <b-button variant="success" onclick="document.getElementById('chemmystery-audio').pause()">
          <feather-icon icon="PauseIcon" />
        </b-button>
        <b-button variant="success" onclick="document.getElementById('chemmystery-audio').muted=!document.getElementById('chemmystery-audio').muted">
          <feather-icon icon="VolumeXIcon" />
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardText, BCol,
  BImg,
  BLink,
  BListGroup,
  BListGroupItem,
  BRow,
  BTable,
  BBadge,
  BFormTextarea, BFormFile
} from 'bootstrap-vue'
import {useEcommerceUi} from "@/views/shop/useEcommerce";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BImg,
    BListGroup,
    BListGroupItem,
    BButton,
    BBadge,
    BFormTextarea,
    BFormFile,
    BButtonGroup,
  },
  data() {
    return {
      agnieszka: require('@/assets/images/chemmaster/agnieszka.png'),
      data: [],
      new_question: "",
      file: ""
    }
  },
  created() {
    this.fetchCourses()
  },
  setup() {
    const {handleCartActionClick} = useEcommerceUi();

    return {handleCartActionClick};
  },
  methods: {
    fetchCourses() {
      this.$http.get(`${this.$store.state.apiDomain}/api/chemmystery/getQuestions`).then(response => {
        this.data = response.data;

        setTimeout(function () {
          let audio = document.getElementById('chemmystery-audio');
          audio.volume = 0.4;
          document.getElementById('chemmystery-audio').play();
        }, 500);

      })
    },
    addQuestion() {

      let fileToUpload = this.$refs.fileInput.files[0];
      let formData = new FormData();

      formData.append('image', fileToUpload);
      formData.append('question', this.new_question);

      this.$http.post(`${this.$store.state.apiDomain}/api/chemmystery/postQuestion`, formData)
          .then(response => {
        this.fetchCourses();
        this.new_question = '';
        this.$refs['modal-chemmystery'].hide()
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
.congratulation-medal{
  width: 175px;
  right: 0;
}
.card-congratulation-medal{
  min-height: 170px;
}
.grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  &.wishlist-items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .ecommerce-card {
    overflow: hidden;

    // Item Image
    .item-img {
      padding-top: 0.5rem;
      min-height: 15.85rem;
      display: flex;
      align-items: center;
    }

    // Item content wrapper
    .item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    // Hiding list view items
    .shipping,
    .item-company,
    .item-options .item-price {
      display: none;
    }

    .item-options {
      display: flex;
      flex-wrap: wrap;

      .btn-cart,
      .btn-wishlist {
        flex-grow: 1;
        border-radius: 0;
      }
    }

    .item-name {
      margin-top: 0.75rem;
    }

    // Item Description
    .item-description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 0.2rem;
    }

    .card-body {
      padding: 1rem;
    }
  }
}
@media all and (max-width: 768px){
  .grid-view {
    grid-template-columns: 1fr;
  }
}
</style>
